<!--
 * @Descripttion:
 * @version: 1.0
 * @Company: ZYZN
 * @Author: Niklaus
 * @Date: 2021-01-08 14:44:08
-->
<template>
	<div>
		<div v-show="extracting">
			抽取中...
		</div>
		<div v-show="!extracting">
			<!-- 项目信息 -->
			<div class="pad-row">
				<div class="whiteBg pad-row tt">
					<p><span>项目编号</span><span>{{project.projectNum}}</span></p>
					<p><span>项目名称</span><span>{{project.projectName}}</span></p>
					<p><span>采购单位</span><span>{{project.purchaseOrg}}</span></p>
					<p><span>评审时间</span><span>{{project.reviewDatetime}}</span></p>
					<p><span>评审地点</span><span>{{project.reviewSite}}</span></p>
				</div>
				<div class="whiteBg pad-row mar-t2 tt">
					<p><span>包组编号</span><span>{{project.packageNum}}</span></p>
					<p><span>专家人数</span><span>{{project.expertNum}}</span></p>
					<p class="flex-between" style="display:flex;width:100%">
						<span>评审专业</span>
						<button class="btn f35 selectBtn" v-show="!allAsign" @click.prevent="onSelectMajors">选择专业</button>
					</p>
				</div>
				<div class="whiteBg" style="padding:0.2rem 0.3rem">
					<ul class="w-100">
						<li class="flex-between" v-for="(item,index) in allOrgExtrMajors" style="margin-top: 0.2rem">
							<span class="center van-ellipsis greenLi fw">
								<i class="del" v-if="item.canDel=='1'" @click="removeChoosedMajor(item,index)"></i>
								{{ item.parentIdDictText +"-" + showMajorAlias(item) }}
							</span>
							<input type="number" placeholder="指定人数" class="step" v-model="item.num" readonly>
						</li>
					</ul>
				</div>
				<!-- 轮次 -->
				<div class="whiteBg mar-t2 pad25" v-for="(item,index) in rounds" :key="index+'round'">
					<div class="flex-between">
						<p style="color:#A6A6A6" class="f3">第{{item.roundNum}}轮专家确认</p>
					</div>
					<div v-for="(expertItem,index) in item.experts" :key="index+'expert'">
						<div class="flex-item">
							<p class="fc fw w15">{{expertItem.name}}</p>
							<p class="c4">{{expertItem.mobilePhone}}</p>

							<p class="gray2 w15 flex-between" v-if="expertItem.confirm=='2'" @click="showConfirm(expertItem)">
								<span class="f35">请确认</span>
								<i class="icon-arrow wh06"></i>
							</p>
							<p class="gray2 w15 flex-between" v-else-if="expertItem.confirm=='1'" @click="showExpertInfo(expertItem)">
								<span class="f35" style="color:#00A8FF">参与</span>
								<i class="icon-arrow wh06"></i>
							</p>
							<p class="gray2 w15 flex-between" v-else-if="expertItem.confirm=='0'" @click="showExpertInfo(expertItem)">
								<span class="f35" style="color:#FF6F00">不参与</span>
								<i class="icon-arrow wh06"></i>
							</p>
						</div>
						<p v-if="expertItem.majorName" class="van-ellipsis">{{ expertItem.majorName }}</p>
					</div>
				</div>
			</div>
			<div style="height:1.8rem"></div>
			<!---操作步骤-->
			<div class="fixed-bottom whiteBg w-100 pad25">
				<button @click="terminateProject()"><i class="icon-del"></i>终止项目</button>
				<button class="w-100 btn" :disabled="!nextEnabled" @click="next()">{{nextStepTitle}}</button>
				<button class="w-100 btn" @click="canFinishExtract()">完成项目</button>
			</div>
		</div>
		<van-popup v-model="noResult" class="popupClass">
			<div class="flex-col pad">
				<h3 class="red">该项目无抽取结果</h3>
				<p class="gray2 f35 mar-t2">已无匹配的评审专家，请点击下面按钮，重新设置抽取项目</p>
				<img src="/static/img/null/result.png" style="width:60%;margin:0.3rem 0">
				<button class="btn f35" @click="noResult = false">重新抽取</button>
			</div>
		</van-popup>
		<!-- 专家确认弹框 -->
		<van-popup v-model="confirmDlg" class="popupClass">
			<div class="temp whiteBg">
				<h3 class="fc f5">{{confirmExpert.name}}</h3>
				<p>
					手机号：{{confirmExpert.mobilePhone}}
					<a :href="'tel:' + confirmExpert.mobilePhone"><i class="icon-phone wh05 mar-l2"></i></a>
				</p>
				<div v-if="confirmExpert.majorName">
					<p>评审专业</p>
					<p class="van-ellipsis fc pad12">{{ confirmExpert.majorName }}</p>
				</div>
				<p>是否参与</p>
				<van-radio-group v-model="isJoin" direction="horizontal" checked-color="#00CCB2" class="mar-t2">
					<van-radio name="1">参与</van-radio>
					<van-radio name="0">不参与</van-radio>
				</van-radio-group>

				<textarea rows="5" v-model="explain" class="mar-t2 w-100" placeholder="请在这里输入不参与的原因…" v-show="isJoin =='0'"></textarea>
				<div class="center">
					<button class="btn f35" @click="doConfirm()">确定</button>
				</div>
			</div>
		</van-popup>

		<!-- 显示已确认的专家信息 -->
		<van-popup v-model="showExpertModal" class="popupClass">
			<div class="temp whiteBg">
				<h3 class="fc f5">{{confirmExpert.name}}</h3>
				<p>
					手机号：{{confirmExpert.mobilePhone}}
					<a :href="'tel:' + confirmExpert.mobilePhone"><i class="icon-phone wh05 mar-l2"></i></a>
				</p>
				<div v-if="confirmExpert.majorName">
					<p>评审专业</p>
					<p class="van-ellipsis fc pad12">{{ confirmExpert.majorName }}</p>
				</div>
				<p>是否参与</p>
				<van-radio-group v-model="confirmExpert.confirm" direction="horizontal" checked-color="#00CCB2" class="mar-t2">
					<van-radio name="1" class="ttt">参与</van-radio>
					<van-radio name="0" class="ttt">不参与</van-radio>
				</van-radio-group>
				<p v-show="confirmExpert.confirm =='0'">
					{{ confirmExpert.explain }}
				</p>
				<div class="center">
					<button class="btn f35" @click="showExpertModal = false">关闭</button>
				</div>
			</div>
		</van-popup>
	</div>
</template>

<script>
	/**
	 *  抽取专家后需确认专家是否参与
	 *  全部确认完成后，若不足专家人数，可补抽
	 *  点击 补抽后 抽取一个符合条件的专家，抽取时无 动画
	 *
	 * **/
	import {
		Popup,
		Stepper,
		Switch,
		RadioGroup,
		Radio,
		Dialog
	} from 'vant'
	export default {
		data() {
			return {
				//抽取中
				extracting: false,
				//项目
				project: {},
				//抽取规则
				criterias: [],
				//映射关系
				maps: [],
				//轮次
				rounds: [],
				//弹层确认
				confirmDlg: false,
				//待确认专家
				confirmExpert: {},
				//是否参加
				isJoin: '1',
				//不参与原因
				explain: '',
				//抽完
				extractReached: false,
				//进入来源:0,step1;1,专业选择;2,场景复现
				fromVal: '0',
				//选中的专业数组
				orgExtrMajors: [],
				//不可更改的评审专业数组
				uneditableOrgExtrMajors: [],
				//全部评审专业
				allOrgExtrMajors: [],
				// 是否有抽取结果
				noResult: false,
				//下一步标题
				nextStepTitle: "补抽专家",
				/** 显示已确认的专家信息的Modal */
				showExpertModal: false,
				allAsign: true, //评审专业全部指定了人数
				nextEnabled: true, //下一步是否可以点击执行
				workweixin: false,				//企业微信
				openUserid:	"",
			}
		},
		components: {
			[Popup.name]: Popup,
			[Stepper.name]: Stepper,
			[Switch.name]: Switch,
			[Radio.name]: Radio,
			[RadioGroup.name]: RadioGroup,
			[Dialog.name]: Dialog
		},
		beforeMount() {
			this.$emit('onActive', 2)
		},
		created() {
			//企微微信标志
			this.workweixin = this.$ls.get("expert_workweixin_flag")&&"1"==this.$ls.get("expert_workweixin_flag")
			if(this.workweixin){
				this.openUserid = this.$ls.get("expert_workweixin_user").open_userid
			}
			//
			if (this.$route.query.fromPage) {
				if ("selectmajors" == this.$route.query.fromPage) {
					this.fromVal = '1'
				} else if ("resume" == this.$route.query.fromPage) {
					this.fromVal = '2'
				}
			}
			////正常抽取
			if (this.fromVal == '0') {
				//从缓存读取项目信息
				this.project = this.$ls.get("temporary-project")
				this.maps = this.$ls.get("temporary-project-maps")
				//不可更改
				this.uneditableOrgExtrMajors = this.$ls.get("temporary-uneditableOrgExtrMajors")
				////////////////////////当前页面刷新////////////////////////////////////
				if (typeof this.$route.params.firstEnter == 'undefined' || this.$route.params.firstEnter != '1') {
					//新增的评审专业
					this.orgExtrMajors = this.$ls.get("orgExtrMajors")
					if (this.orgExtrMajors.length > 0) {
						for (let ii = 0; ii < this.orgExtrMajors.length; ii++) {
							this.orgExtrMajors[ii].canDel = '1'
						}
					}
					//合并
					this.mergeOrgMajors()
					//判断是否是评审专业全部指定
					let numReg = /^[1-9][0-9]*$/
					for (let ii = 0; ii < this.allOrgExtrMajors.length; ii++) {
						let num1 = 0
						if (this.allOrgExtrMajors[ii].num) {
							if (numReg.test(this.allOrgExtrMajors[ii].num)) {
								num1 = parseInt(this.allOrgExtrMajors[ii].num)
							}
						}
						if (num1 <= 0) {
							this.allAsign = false
							break
						}
					}
					//抽取轮次
					this.rounds = this.$ls.get("temporary-rounds")
				} else {
					this.orgExtrMajors = []
					this.rounds = []
					//合并
					this.mergeOrgMajors()
					//判断是否是评审专业全部指定
					let numReg = /^[1-9][0-9]*$/
					for (let ii = 0; ii < this.allOrgExtrMajors.length; ii++) {
						let num1 = 0
						if (this.allOrgExtrMajors[ii].num) {
							if (numReg.test(this.allOrgExtrMajors[ii].num)) {
								num1 = parseInt(this.allOrgExtrMajors[ii].num)
							}
						}
						if (num1 <= 0) {
							this.allAsign = false
							break
						}
					}
					//项目状态-抽取中
					let user = this.$ls.get("edCurrentUser")
					if (this.project.id != '') {
						this.project.updateBy = user.id
					} else {
						this.project.createBy = user.id
					}
					this.project.projectStatus = '01'
					//
					this.extracting = true
					//
					let openid = this.workweixin?this.openUserid:this.$ls.get("openid")
					let token = this.$ls.get("token")
					//先保存项目
					let __this = this
					let postData = {}
					postData.project = this.project
					postData.round = 1
					postData.majorMap = this.maps
					this.$ajax({
						url: "/gateway/ed/project/tbEdProject/temporarySaveProject",
						method: "post",
						headers: {
							"X-Access-Token": token,
						},
						params: {
							openid: openid,
						},
						data: postData,
					}).then((res) => {
						if (res.status == "200" && res.data.retCode == "0") {
							//回写项目主键，千万别忘！！！！！！！
							__this.project.id = res.data.projectId
							//开始抽取
							__this.extract()
						} else {
							__this.extracting = false
						}
					}).catch((err) => {
						__this.extracting = false
						// alert(JSON.stringify(err))
					})
				}
			}
			//选择专业
			else if (this.fromVal == '1') {
				//项目
				this.project = this.$ls.get("temporary-project")
				//映射关系
				this.maps = this.$ls.get("temporary-project-maps")
				//项目抽取状态
				this.extracting = this.$ls.get("temporary-extracting")
				//轮次
				this.rounds = this.$ls.get("temporary-rounds")
				//不可更改评审专业
				this.uneditableOrgExtrMajors = this.$ls.get("temporary-uneditableOrgExtrMajors")
				//处理新选择
				this.orgExtrMajors = this.$ls.get("orgExtrMajors")
				//剔除不可更改评审专业
				if (this.orgExtrMajors.length > 0) {
					for (let ii = this.orgExtrMajors.length - 1; ii >= 0; ii--) {
						//查找
						let finded = false
						for (let jj = 0; jj < this.uneditableOrgExtrMajors.length; jj++) {
							if (this.orgExtrMajors[ii].id == this.uneditableOrgExtrMajors[jj].id) {
								finded = true
								break
							}
						}
						//删除
						if (finded) {
							this.orgExtrMajors.splice(ii, 1)
						}
						//更改状态
						else {
							this.orgExtrMajors[ii].canDel = '1'
						}
					}
				}
				//合并
				this.mergeOrgMajors()
				//判断是否是评审专业全部指定
				let numReg = /^[1-9][0-9]*$/
				for (let ii = 0; ii < this.allOrgExtrMajors.length; ii++) {
					let num1 = 0
					if (this.allOrgExtrMajors[ii].num) {
						if (numReg.test(this.allOrgExtrMajors[ii].num)) {
							num1 = parseInt(this.allOrgExtrMajors[ii].num)
						}
					}
					if (num1 <= 0) {
						this.allAsign = false
						break
					}
				}
			}
			//场景复现
			else if (this.fromVal == '2') {
				let projectId = this.$route.query.projectId
				//查询抽取数据
				let __this = this
				let openid = this.workweixin?this.openUserid:this.$ls.get("openid")
				let token = this.$ls.get("token")
				this.$ajax({
					url: "/gateway/ed/extract/getExtracting",
					method: "post",
					headers: {
						"X-Access-Token": token,
					},
					params: {
						openid: openid,
						projectId: projectId,
					},
				}).then((res) => {
					if (res.status == "200" && res.data.retCode == "0") {
						//项目
						__this.project = res.data.project
						//轮次
						if (res.data.rounds)
							__this.rounds = res.data.rounds
						//评审专业-不可更改
						__this.uneditableOrgExtrMajors = res.data.orgExtrMajors
						for (let ii = 0; ii < __this.uneditableOrgExtrMajors.length; ii++) {
							__this.uneditableOrgExtrMajors[ii].canDel = '0'
						}
						//合并评审专业
						__this.mergeOrgMajors()
						//判断是否是评审专业全部指定
						let numReg = /^[1-9][0-9]*$/
						for (let ii = 0; ii < __this.allOrgExtrMajors.length; ii++) {
							let num1 = 0
							if (__this.allOrgExtrMajors[ii].num) {
								if (numReg.test(__this.allOrgExtrMajors[ii].num)) {
									num1 = parseInt(__this.allOrgExtrMajors[ii].num)
								}
							}
							if (num1 <= 0) {
								__this.allAsign = false
								break
							}
						}
						//
						__this.canSupplyExtract()
					}
				}).catch((err) => {

				})
			}
			console.log(this);
		},
		methods: {
			//完成前判断
			canFinishExtract: function() {
				let retval = this.canSupplyExtract()
				if (retval == "1") {
					this.$toast("有专家未确认！")
					return
				} else if (retval == "0") {
					let __this = this
					this.$dialog.confirm({
						title: '完成项目',
						message: '参与专家人数少于需求人数！是否仍然要完成项目？'
					}).then(() => {
						__this.finishExtract()
					})
				} else {
					this.finishExtract()
				}
			},
			//抽取
			extract: function() {
				let __this = this
				let openid = this.workweixin?this.openUserid:this.$ls.get("openid")
				let token = this.$ls.get("token")
				let postData = {}
				postData.project = this.project
				postData.round = 1
				postData.majorMap = this.maps
				this.$ajax({
					url: "/gateway/ed/extract/extract",
					method: "post",
					headers: {
						"X-Access-Token": token,
					},
					params: {
						openid: openid,
					},
					data: postData,
				}).then((res) => {
					if (res.status == "200" && res.data.retCode == "0") {
						//处理抽取结果
						__this.processResult(res.data.result)
						//抽取成功
						__this.extracting = false
					} else {
						this.$toast(res.data.errMsg)
						__this.extracting = false
					}
				}).catch((err) => {
					__this.extracting = false
					alert(JSON.stringify(err))
				})
			},
			//选择专业
			onSelectMajors: function() {
				///////////////保存当前状态///////////////////
				//项目
				this.$ls.set("temporary-project", this.project)
				//映射关系
				this.$ls.set("temporary-project-maps", this.maps)
				//项目抽取状态
				this.$ls.set("temporary-extracting", this.extracting)
				//轮次
				this.$ls.set("temporary-rounds", this.rounds)
				//传回当前选择的专业--将不可更改评审专业合并传入,目的从所属评审专业集合中剔除，避免重选
				this.orgExtrMajors = this.orgExtrMajors.concat(this.uneditableOrgExtrMajors)
				this.$ls.set("orgExtrMajors", this.orgExtrMajors)
				//不可更改的评审专业
				this.$ls.set("temporary-uneditableOrgExtrMajors", this.uneditableOrgExtrMajors)
				//
				this.$router.push({
					name: 'selectmajors',
					query: {
						fromPage: 'step3'
					}
				})
			},
			//抽取结果出来
			processResult: function(result) {
				let expertIds = [];
				//解析评审专业抽取-数组
				/** 指定人数的专业数组 */
				let confirmMajors = []
				if (result.extractedMajors) {
					for (let i = 0; i < result.extractedMajors.length; i++) {
						let arys = result.extractedMajors[i].extracted.split(",")
						for (let j = 0; j < arys.length; j++) {
							if (arys[j] == '' || arys[j] == null)
								continue
							confirmMajors.push({
								majorId: result.extractedMajors[i].majorId,
								expertId: arys[j]
							})
							expertIds.push(arys[j])
						}
					}
				}
				//解析随机抽取
				if (result.randomExtracted) {
					let arys = result.randomExtracted.split(",")
					if (arys.length > 0) {
						for (let i = 0; i < arys.length; i++) {
							if (arys[i] == '' || arys[i] == null)
								continue
							expertIds.push(arys[i])
						}
					}
				}
				//抽取到专家id结合
				if (expertIds.length > 0) {
					//转成字符串
					let strTmp = ""
					for (let ii = 0; ii < expertIds.length; ii++) {
						if (expertIds[ii] == '')
							continue
						strTmp += expertIds[ii] + ','
					}
					let __this = this
					let openid = this.workweixin?this.openUserid:this.$ls.get("openid")
					let token = this.$ls.get("token")
					this.$ajax({
						url: "/gateway/ed/extract/getExtractedExpertsInfo",
						method: "post",
						headers: {
							"X-Access-Token": token,
						},
						params: {
							openid: openid,
							experts: strTmp,
							projectId: this.project.id,
						},
					}).then((res) => {
						//写本轮抽取结果
						let cccc = []
						let tempRound = __this.rounds.length
						if (res.status == '200' && res.data.retCode == '0') {
							if (res.data.experts) {
								/** 遍历数据时，将抽取专家对应的专业ID存入，若未指定人数则不处理专业信息 */
								for (let ii = 0; ii < res.data.experts.length; ii++) {
									let temp = {
										name: res.data.experts[ii].name,
										mobilePhone: res.data.experts[ii].mobilePhone,
										confirm: '2',
										id: res.data.experts[ii].id,
										majorName: res.data.experts[ii].majorName ? res.data.experts[ii].majorName : ""
									}
									cccc.push(temp)
								}
							}
						}
						tempRound++
						__this.rounds.push({
							roundNum: tempRound,
							experts: cccc
						})
						//写入缓存
						__this.$ls.set("temporary-rounds", __this.rounds)
						if (tempRound > 1) {
							this.$nextTick(() => {
								window.scrollTo(0, document.body.clientHeight);
							})
						}
						//校验
						__this.canSupplyExtract()
						//先判断
					}).catch((err) => {
						alert(JSON.stringify(err))
					})
					//将原来新增的评审专业转到不可修改数组
					if (this.orgExtrMajors.length > 0) {
						for (let ii = 0; ii < this.orgExtrMajors.length; ii++) {
							this.orgExtrMajors[ii].canDel = '0'
						}
						this.uneditableOrgExtrMajors = this.uneditableOrgExtrMajors.concat(this.orgExtrMajors)
						this.allOrgExtrMajors = this.uneditableOrgExtrMajors
						//写缓存--不可更改的评审专业
						this.$ls.set("temporary-uneditableOrgExtrMajors", this.uneditableOrgExtrMajors)
						//写缓存-置空当前新增评审专业
						this.orgExtrMajors = []
						this.$ls.set("orgExtrMajors", this.orgExtrMajors)
					}
					//有结果
					this.noResult = false
					// //首次抽取有结果
					// if(this.firstExtractNoResult)
					// 	this.firstExtractNoResult = false

				} else {
					//本轮次无抽取结果
					this.noResult = true
				}

			},
			//终止项目
			terminateProject: function() {
				this.$dialog.confirm({
					title: '终止项目',
					message: '项目终止后不可恢复，请谨慎操作！'
				}).then(() => {

					let ddd = new Date(this.project.reviewDatetime)
					let reviewDatetime = ddd.getTime()
					let openid = this.workweixin?this.openUserid:this.$ls.get("openid")
					let token = this.$ls.get("token")
					this.$ajax({
						url: "/gateway/ed/extract/terminateProject",
						method: "post",
						headers: {
							"X-Access-Token": token,
						},
						params: {
							projectId: this.project.id,
							orgId: this.project.orgId,
							reviewDatetime: reviewDatetime
						},
					}).then((res) => {
						if (res.status == '200' && res.data.retCode == '0') {
							this.$router.push('/record')
						}
					}).catch((err) => {
						alert(JSON.stringify(err))
					})

				}).catch(() => {})
			},
			//确认
			doConfirm: function() {
				//提交
				//判断原因是否填写
				if (this.isJoin == '0') {
					let explainTmp = this.explain.replace(/\s+/g, "")
					if (explainTmp == '') {
						this.$toast("请填写不参加原因！")
						return
					}
				}
				if (this.explain.length > 100) {
					this.$toast("不参加原因过长，请修改！")
					return
				}
				//提交
				let ddd = new Date(this.project.reviewDatetime)
				let reviewDatetime = ddd.getTime()
				let __this = this
				let openid = this.workweixin?this.openUserid:this.$ls.get("openid")
				let token = this.$ls.get("token")
				this.$ajax({
					url: "/gateway/ed/extract/confirmExpert",
					method: "post",
					headers: {
						"X-Access-Token": token,
					},
					params: {
						projectId: this.project.id,
						orgId: this.project.orgId,
						expertId: this.confirmExpert.id,
						isJoin: this.isJoin == '1',
						explain: this.explain,
						reviewDatetime: reviewDatetime
					},
				}).then(res => {

					if (res.status == '200' && res.data.retCode == '0') {
						//修改专家确认状态
						for (let ii = 0; ii < __this.rounds.length; ii++) {
							let finded = false
							for (let jj = 0; jj < __this.rounds[ii].experts.length; jj++) {
								if (__this.rounds[ii].experts[jj].id == __this.confirmExpert.id) {
									__this.rounds[ii].experts[jj].confirm = __this.isJoin
									__this.rounds[ii].experts[jj].explain = __this.explain
									finded = true
									break
								}
							}
							if (finded) {
								break
							}
						}
						//
						__this.canSupplyExtract()
					}
					this.confirmDlg = false
				}).catch((err) => {
					alert(JSON.stringify(err))
				})
			},
			//显示
			showConfirm: function(expert) {
				//默认参加
				this.isJoin = '1'
				//原因置空
				this.explain = ''
				//传入当前专家对象
				this.confirmExpert = expert

				/** 如果该专家有被指定专业 则获取专业名称 */
				if (expert.majorId && !expert.majorName) {
					this.$ajax({
						method: 'get',
						url: "/gateway/ed/wx/wxEdProjectController/getMajorFullName",
						headers: {
							"X-Access-Token": this.$ls.get("token"),
						},
						params: {
							id: expert.majorId,
							openid: (this.workweixin?this.openUserid:this.$ls.get("openid")),
							orgId: this.project.orgId
						},
					}).then(
						({
							data
						}) => {
							if (data.success) {
								this.confirmExpert.majorName = data.message
							}
							this.confirmDlg = true

						}
					).catch(() => {
						this.$toast.fail('请求失败')
						this.confirmDlg = true
					})
				} else this.confirmDlg = true
			},
			//下一步
			next: function() {
				let retval = this.canSupplyExtract()
				//补抽
				if (retval == "0") {
					this.supplyExtract()
				}
				//有未确认专家
				else if (retval == "1") {
					this.$toast("有专家未确认！")
					return
				}
				//完成
				if (retval == "2") {
					// this.finishExtract()
				}
			},
			//完成
			finishExtract: function() {
				let __this = this
				let openid = this.workweixin?this.openUserid:this.$ls.get("openid")
				let token = this.$ls.get("token")
				let user = this.$ls.get("edCurrentUser")
				this.$ajax({
					url: "/gateway/ed/extract/finishExtracted",
					method: "post",
					headers: {
						"X-Access-Token": token,
					},
					params: {
						openid: openid,
						projectId: this.project.id,
						orgId: this.project.orgId,
						userId: user.id
					},
				}).then((res) => {
					if (res.status == '200' && res.data.retCode == '0') {
						this.$ls.set('temp-project', this.project);
						__this.$router.push({
							name: 'step4'
						})

					} else {
						__this.$toast("保存出现异常！")
					}
					// //发送短信
					// __this.sendSms()
					//

				}).catch((err) => {

				})
			},
			//补抽专家
			supplyExtract: function() {
				//轮次
				let round = this.rounds.length + 1
				//项目评审专业映射关系
				let supplyMaps = []
				for (let i = 0; i < this.allOrgExtrMajors.length; i++) {
					//映射关系
					let e1 = {}
					e1.majorId = this.allOrgExtrMajors[i].id
					e1.parentMajorId = this.allOrgExtrMajors[i].parentId
					e1.firstClassId = this.allOrgExtrMajors[i].firstClassId
					//指定人数
					//指定人数
					let num1 = 0
					let numReg = /^[1-9][0-9]*$/
					if (this.allOrgExtrMajors[i].num) {
						if (numReg.test(this.allOrgExtrMajors[i].num)) {
							num1 = parseInt(this.allOrgExtrMajors[i].num)
						}
					}
					if (num1 <= 0) {
						this.allAsign = false
						num1 = 0
					}
					e1.num = num1 + ""
					supplyMaps.push(e1)
				}
				//开始补抽
				let __this = this
				let openid = this.workweixin?this.openUserid:this.$ls.get("openid")
				let token = this.$ls.get("token")
				let postData = {}
				postData.project = this.project
				postData.round = round
				postData.majorMap = supplyMaps
				this.$ajax({
					url: "/gateway/ed/extract/extract",
					method: "post",
					headers: {
						"X-Access-Token": token,
					},
					params: {
						openid: openid,
					},
					data: postData,
				}).then((res) => {
					if (res.status == "200" && res.data.retCode == "0") {
						//处理抽取结果
						__this.processResult(res.data.result)
						//抽取成功
						__this.extracting = false

					} else {
						this.$toast(res.data.errMsg)
						__this.extracting = false
					}
				}).catch((err) => {
					__this.extracting = false
					alert(JSON.stringify(err))
				})
			},
			//移除选择的评审专业
			removeChoosedMajor: function(obj, index) {
				//先移除新增评审专业
				if (this.orgExtrMajors.length > 0) {
					for (let ii = this.orgExtrMajors.length - 1; ii >= 0; ii--) {
						if (this.orgExtrMajors[ii].id == obj.id) {
							this.orgExtrMajors.splice(ii, 1)
						}
					}
				}
				//移除
				this.allOrgExtrMajors.splice(index, 1)
			},
			//校验当前抽取结果是否满足补抽要求
			canSupplyExtract: function() {
				//当前项目需要抽取人数总数
				let total = this.project.expertNum
				//累计
				let accu = 0
				//未确认
				let unconfirmed = false
				//循环
				for (let ii = 0; ii < this.rounds.length; ii++) {
					for (let jj = 0; jj < this.rounds[ii].experts.length; jj++) {
						//已确认参与
						if (this.rounds[ii].experts[jj].confirm == '1') {
							accu++
						} else if (this.rounds[ii].experts[jj].confirm == '2') {
							unconfirmed = true
						}
					}
				}
				//有未确认专家
				if (unconfirmed) {
					return "1"
				}

				//已经满足抽取条件
				if (total == accu) {
					// this.nextStepTitle = "完成"
					this.nextEnabled = false
					return "2"
				}
				return "0"
			},
			//合并不可编辑和新增评审专业数组
			mergeOrgMajors: function() {
				this.allOrgExtrMajors = []
				//
				this.allOrgExtrMajors = this.uneditableOrgExtrMajors.concat(this.orgExtrMajors)
			},

			/**
			 * @Description: 检验指定人数的内容
			 * @Author: Niklaus
			 * @Date: 2021-02-27 09:07:16
			 */
			onInput(dom, item) {
				let tt = /^[1-9][0-9]*$/
				if (!tt.test(dom.value)) {
					dom.value = ''
					item.num = ''
				}
			},

			/**
			 * @Description: 显示已确认的专家信息
			 * @Author: Niklaus
			 * @Date: 2021-02-27 09:08:33
			 * @param {*} expert
			 */
			showExpertInfo(expert) {
				this.confirmExpert = expert
				this.showExpertModal = true
			},
			//发送短信
			sendSms: function() {
				let __this = this
				let openid = this.workweixin?this.openUserid:this.$ls.get("openid")
				let token = this.$ls.get("token")
				this.$ajax({
					url: "/gateway/ed/sms/sendSms",
					method: "get",
					headers: {
						"X-Access-Token": token,
					},
					params: {
						openid: openid,
						projectId: this.project.id,
					},
				}).then((res) => {
					alert(JSON.stringify(res))
				}).catch((err) => {
					console.log(err)
				})
			},
			//优先显示备注
			showMajorAlias(item) {
				if (item.majorAlias != null && item.majorAlias != '') {
					return item.majorAlias
				} else {
					return item.majorName
				}
			},
			//下一个方法
		}
	}
</script>

<style scoped>
	.tt>p {
		display: table-row;
		line-height: 0.8rem;
	}

	.tt>p>span {
		display: table-cell;
		font-weight: 500;
		padding: 0.2rem 0;
	}

	.tt>p>span:first-child {
		width: 2rem;
	}

	.tt>p>span:last-child {
		color: #737373;
	}

	.c4 {
		width: calc(100% - 3.5rem);
	}

	.flex-item {
		padding-top: 0.2rem;
	}

	.fixed-bottom i {
		width: 0.6rem;
		height: 0.6rem;
		margin-right: 0.1rem;
	}

	.fixed-bottom>button {
		width: 33.33%;
		padding: 0.1rem 0.1rem;
		text-align: center;
		font-weight: bold;
	}

	.fixed-bottom>button:first-child {
		padding: 0;
		background-color: #fff;
		color: #FF7A7A;
		font-size: 0.35rem;
	}

	.fixed-bottom>button:nth-child(2) {
		border-radius: 0.5rem 0 0 0.5rem;
		color: #00CCB2;
		background-color: #EDEDED;
	}

	.fixed-bottom>button:nth-child(3) {
		border-radius: 0 0.5rem 0.5rem 0;
		color: white;
		background-color: #00CCB2;
	}

	.w15 {
		width: 1.7rem;
	}

	.temp>p {
		display: inline-table;
		border-bottom: 1px solid #faf5f5;
		width: 100%;
		border-bottom: none;
		padding: 0.1rem 0;
	}

	.temp {
		padding: 0.5rem;
		margin-top: 0.4rem;
	}

	.van-radio--horizontal {
		margin-right: 1rem;
	}

	.temp button {
		padding-top: 0.1rem;
		padding-bottom: 0.1rem;
		width: 50%;
		margin-top: 1rem;
	}

	.fw {
		position: relative;
	}

	.delBtn {
		height: 0.65rem;
		padding: 0 0.35rem;
		line-height: 0.65rem;
		background-color: #FF7A7A;
		color: white;
		border-radius: 0.4rem;
	}

	.addBtn {
		height: 0.65rem;
		padding: 0 0.35rem;
		line-height: 0.65rem;
		background-color: #00CCB2;
		color: white;
		border-radius: 0.4rem;
		margin-left: 0.3rem;
	}

	.del {
		position: absolute;
		top: 0.1rem;
		right: 0.1rem;
		width: 0.45rem;
		height: 0.45rem;
		background-color: #EDEDED;
		content: '-';
		border-radius: 50%;
	}

	.del::before {
		width: 50%;
		height: 1px;
		content: '';
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%) rotate(45deg);
		background-color: #A6A6A6;
	}

	.del::after {
		width: 1px;
		height: 50%;
		content: '';
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%) rotate(45deg);
		background-color: #A6A6A6;
	}

	.greenLi {
		position: relative;
		display: inline-block;
		width: calc(100% - 2rem);
		background-color: transparent;
		border: none;
		color: #00CCB2;
		text-align: left;
		padding: 0;
		padding-right: 0.1rem;
	}

	.step {
		width: 2rem;
		flex-shrink: 0;
		border-radius: 1rem;
		border: 1px solid #00CCB2;
		text-align: center;
	}

	.step::placeholder {
		font-size: 0.36rem;
	}

	.step:focus::placeholder {
		color: transparent;
	}

	.selectBtn {
		height: 0.7rem;
		padding: 0.05rem 0.15rem;
		line-height: 0.6rem
	}

	.popupClass {
		width: 70%;
		border-radius: 0.3rem
	}

	.ttt {
		pointer-events: none
	}
</style>
